<template>
    <div class="container-fluid">
        <Navbar active="helice" />
        <div class="overflow-hidden p-0 cover">
            <b-modal hide-header hide-footer v-model="editModalOpen"
                ><div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="custom-color-secondary">
                            Modifier l'hélice
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <p class="custom-color-secondary">
                            Vous allez modifier l'hèlice
                            <b class="custom-color-primary"
                                >{{ editModalData.idDevice }} -
                                {{ editModalData.DeviceName }}</b
                            >
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-input
                            v-model="deviceName"
                            placeholder="Nouveau nom"
                        ></b-form-input>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-6 text-center">
                        <b-button
                            class="custom-btn-color-primary"
                            v-on:click="editDeviceName"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="col-lg-6 text-center">
                        <b-button class="custom-btn-color-secondary"
                            >Annuler</b-button
                        >
                    </div>
                </div></b-modal
            >
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <table class="table table-striped custom-bordered">
                            <thead>
                                <tr>
                                    <td scope="col">
                                        <label class="container text-center">
                                            <input
                                                type="checkbox"
                                                v-model="checkAll"
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td class="custom-table-th">Code Client</td>
                                    <td class="custom-table-th">Nom</td>
                                    <td class="custom-table-th">Modèle</td>
                                    <td class="custom-table-th">MAC adress</td>
                                    <td class="custom-table-th">Statut</td>
                                    <td class="custom-table-th">Groupe</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="helice of helices"
                                    :key="helice.idDevice"
                                >
                                    <td>
                                        <label class="container">
                                            <input
                                                type="checkbox"
                                                v-model="
                                                    checkTab[helice.idDevice]
                                                "
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td>{{ helice.idDevice }}</td>
                                    <td>{{ helice.DeviceName }}</td>
                                    <td>{{ helice.Model }}</td>
                                    <td>{{ helice.MacIpAddress }}</td>
                                    <td>{{ helice.RunStatus }}</td>
                                    <td>{{ helice.IdGroup }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <b-card-group deck>
                                <b-card
                                    header-class="custom-card-header"
                                    class="custom-card-body"
                                >
                                    <template #header>
                                        Client - Contenu Hélice
                                    </template>
                                    <b-card-body class="reduce-padding"
                                        ><div
                                            class="row"
                                            v-for="groupe of groupes"
                                            :key="groupe.code"
                                        >
                                            <div class="col-lg-2">
                                                <label class="container">
                                                    <input type="checkbox" />
                                                    <span
                                                        class="checkmark"
                                                    ></span>
                                                </label>
                                            </div>
                                            <div
                                                class="
                                                    col-lg-10
                                                    text-white text-left
                                                "
                                            >
                                                {{ groupe.code }} -
                                                {{ groupe.name }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span>
                                                    <label class="container">
                                                        <input
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="
                                                                checkmark-checked
                                                            "
                                                        ></span>
                                                    </label>
                                                    <b>Valider</b>
                                                </span>
                                            </div>
                                            <div class="col-lg-6">
                                                <span>
                                                    <label class="container">
                                                        <input
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="
                                                                checkmark:after
                                                            "
                                                        ></span>
                                                    </label>
                                                    <b>Annuler</b>
                                                </span>
                                            </div>
                                        </div></b-card-body
                                    ></b-card
                                ></b-card-group
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <b-card-group deck>
                                <b-card
                                    header-class="custom-card-header"
                                    class="custom-card-body"
                                >
                                    <template #header> Opérations </template>
                                    <b-card-body class="reduce-padding"
                                        ><div class="row text-left">
                                            <div
                                                class="col-lg-12"
                                                v-on:click="powerondevice"
                                            >
                                                <span
                                                    class="text-white pointer"
                                                >
                                                    Power on
                                                </span>
                                                <span class="text-danger">
                                                    (hélices séléctionnées)
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row text-left">
                                            <div
                                                class="col-lg-12"
                                                v-on:click="poweroffdevice"
                                            >
                                                <span
                                                    class="text-white pointer"
                                                >
                                                    Power off
                                                </span>
                                                <span class="text-danger">
                                                    (hélices séléctionnées)
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row text-left">
                                            <div
                                                class="col-lg-12"
                                                v-on:click="editDevice"
                                            >
                                                <span
                                                    class="text-white pointer"
                                                >
                                                    Modifier client
                                                </span>
                                                <span class="text-danger">
                                                    (client séléctionné)
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row text-left">
                                            <div class="col-lg-12">
                                                <span
                                                    class="text-white pointer"
                                                >
                                                    Formatage
                                                </span>
                                                <span class="text-danger">
                                                    (formate l'hélice
                                                    selectionnée)
                                                </span>
                                            </div>
                                        </div></b-card-body
                                    ></b-card
                                ></b-card-group
                            >
                        </div>
                    </div>
                    {{ user }}
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <b-card-group deck>
                                <b-card
                                    header-class="custom-card-header"
                                    class="custom-card-body"
                                >
                                    <template #header>
                                        Associé le client au groupe
                                    </template>
                                    <b-card-body class="reduce-padding"
                                        ><div
                                            class="row"
                                            v-for="groupe of groupes"
                                            :key="groupe.idGroup"
                                        >
                                            <div class="col-lg-2">
                                                <label class="container">
                                                    <input
                                                        type="radio"
                                                        v-model="editCheck"
                                                        :value="groupe.idGroup"
                                                    />
                                                    <span
                                                        class="checkmark"
                                                    ></span>
                                                </label>
                                            </div>
                                            <div
                                                class="
                                                    col-lg-10
                                                    text-white text-left
                                                "
                                            >
                                                {{ groupe.idGroup }} -
                                                {{ groupe.groupName }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span>
                                                    <label class="container">
                                                        <input
                                                            type="button"
                                                            v-on:click="
                                                                addToGroup
                                                            "
                                                        />
                                                        <span
                                                            class="
                                                                checkmark-checked
                                                            "
                                                        ></span>
                                                    </label>
                                                    <b>Valider</b>
                                                </span>
                                            </div>
                                            <div class="col-lg-6">
                                                <span>
                                                    <label class="container">
                                                        <input
                                                            type="button"
                                                            v-on:click="
                                                                closeAddToGroup
                                                            "
                                                        />
                                                        <span
                                                            class="
                                                                checkmark-checked
                                                            "
                                                        ></span>
                                                    </label>
                                                    <b>Annuler</b>
                                                </span>
                                            </div>
                                        </div></b-card-body
                                    ></b-card
                                ></b-card-group
                            >
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <b-card-group deck>
                                <b-card
                                    header-class="custom-card-header"
                                    class="custom-card-body"
                                >
                                    <template #header>
                                        Hèlice non associé sur le réseau
                                    </template>
                                    <b-card-body class="reduce-padding"
                                        ><div
                                            class="row"
                                            v-for="unbanked of unbankeds"
                                            :key="unbanked.idDevice"
                                        >
                                            <div class="col-lg-2">
                                                <label class="container">
                                                    <input type="checkbox" />
                                                    <span
                                                        class="checkmark"
                                                    ></span>
                                                </label>
                                            </div>
                                            <div
                                                class="
                                                    col-lg-10
                                                    text-white text-left
                                                "
                                            >
                                                {{ unbanked.idDevice }} -
                                                {{ unbanked.DeviceName }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span>
                                                    <label class="container">
                                                        <input
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="checkmark"
                                                        ></span>
                                                    </label>
                                                    <b>Valider</b>
                                                </span>
                                            </div>
                                            <div class="col-lg-6">
                                                <span>
                                                    <label class="container">
                                                        <input
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="checkmark"
                                                        ></span>
                                                    </label>
                                                    <b>Annuler</b>
                                                </span>
                                            </div>
                                        </div></b-card-body
                                    ></b-card
                                ></b-card-group
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar";
export default {
    data: function () {
        return {
            helices: [],
            groupes: [],
            checkAll: false,
            checkTab: {},
            editCheck: "",
            user: {},
            unbankeds: "",
            editModalOpen: false,
            editModalData: {},
            deviceName: "",
            cloudLib: [],
        };
    },
    async beforeMount() {
        this.user = this.$store.state.user.user;

        this.groupes = await this.$store.dispatch("admin/getAllGroupList");
        this.helices = await this.$store.dispatch("admin/getAllDevice");
        this.unbankeds = await this.$store.dispatch("group/getDeviceGroup", {
            groupId: 0,
        });

        for (let helice of this.helices) {
            this.checkTab[helice.idDevice] = false;
        }
        // this.helices = this.$store.dispatch("helice/getHelice", {userName: this.user.Name})
    },
    components: {
        Navbar,
    },
    watch: {
        checkAll: {
            handler() {
                this.checkedAll();
            },
            deep: true,
        },
    },
    methods: {
        checkedAll: function () {
            if (this.checkAll == false) {
                this.helices.forEach((element) => {
                    this.checkTab[element.idDevice] = false;
                });
            } else {
                this.helices.forEach((element) => {
                    this.checkTab[element.idDevice] = true;
                });
            }
        },
        editDevice: function () {
            let i = 0;
            let keyData = "";
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value === true) {
                    i++;
                    keyData = key;
                }
            }
            if (i === 1) {
                this.editModalOpen = true;
                this.helices.forEach((helice) => {
                    if (helice.idDevice == keyData) {
                        this.editModalData = helice;
                    }
                });
                this.editModalOpen = true;
            }
        },

        addToGroup: async function () {
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    let macadress = "";
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            macadress = helice.MacIpAddress;
                        }
                    }

                    await this.$store.dispatch("admin/addDeviceToGroup", {
                        userName: this.user.username,
                        deviceId: macadress,
                        groupId: this.editCheck,
                    });
                }
            }
            this.helices = await this.$store.dispatch("admin/getAllDevice");
            this.unbankeds = await this.$store.dispatch(
                "group/getDeviceGroup",
                {
                    groupId: 0,
                }
            );
        },
        closeAddToGroup: async function () {},

        editDeviceName: async function () {
            await this.$store.dispatch("admin/updateDeviceName", {
                userName: this.user.username,
                deviceId: this.editModalData.MacIpAddress,
                deviceName: this.deviceName,
            });
            this.helices = await this.$store.dispatch("admin/getAllDevice");
            this.checkTab = {};
            for (let helice of this.helices) {
                this.checkTab[helice.idDevice] = false;
            }
            this.editModalOpen = false;
        },
        powerondevice: async function () {
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    let macadress = "";
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            macadress = helice.MacIpAddress;
                        }
                    }
                    await this.$store.dispatch("admin/poweronoffdevice", {
                        userName: this.user.username,
                        deviceId: macadress,
                        devicePowerOff: 0,
                        devicePowerOn: 1,
                    });
                }
            }
        },
        poweroffdevice: async function () {
            for (const [key, value] of Object.entries(this.checkTab)) {
                if (value == true) {
                    let macadress = "";
                    for (let helice of this.helices) {
                        if (helice.idDevice == key) {
                            macadress = helice.MacIpAddress;
                        }
                    }
                    await this.$store.dispatch("admin/poweronoffdevice", {
                        userName: this.user.username,
                        deviceId: macadress,
                        devicePowerOff: 1,
                        devicePowerOn: 0,
                    });
                }
            }
        },
    },
};
</script>
<style>
.custom-card-header {
    color: #0e476b;
    background-color: white;
}
.custom-card-body {
    background-color: #11a9b9;
    border: 2px solid #11a9b9;
}
.reduce-padding {
    padding-top: 0;
    padding-bottom: 0;
}
</style>
